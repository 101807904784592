<template>
  <svg viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1539_2309)">
      <path
        d="M20.5016 14.1545L20.5251 14.2933H20.6659H43.9085C49.0713 14.2933 53.2697 18.35 53.2697 23.3175C53.2697 28.2852 49.0813 32.3417 43.9085 32.3417H20.0838C15.8634 32.3417 12.4215 35.6157 12.4215 39.6569C12.4215 43.6982 15.8634 46.9721 20.0838 46.9721H36.6784H37.0808L36.7962 46.6876L32.4183 42.3097C32.4178 42.3092 32.4173 42.3087 32.4169 42.3082C32.1017 41.9745 32.1019 41.454 32.4175 41.1205C32.7404 40.7898 33.2777 40.7794 33.621 41.1039C33.6215 41.1044 33.622 41.1049 33.6225 41.1054L39.7364 47.2295L39.7365 47.2296C40.0696 47.5628 40.0696 48.1006 39.7365 48.4338L33.6195 54.5508L33.7373 54.6687L33.6195 54.5508L33.6194 54.5509L33.6193 54.5509L33.619 54.5513L33.6177 54.5526L33.6131 54.5572L33.5991 54.5712L33.7169 54.6891L33.5991 54.5712C33.2702 54.9001 32.7324 54.8884 32.3949 54.5508C32.0633 54.2192 32.0712 53.6735 32.4122 53.3496L32.4122 53.3497L32.4154 53.3465L36.7861 48.9655L37.0699 48.6811H36.6681H20.0736C14.9108 48.6811 10.7124 44.6245 10.7124 39.6569C10.7124 34.6895 14.9108 30.6327 20.0838 30.6327H43.9188C48.1292 30.6327 51.5709 27.3587 51.5709 23.3176C51.5709 19.2765 48.1291 16.0025 43.9188 16.0025H20.6761H20.5355L20.5119 16.1411C20.0846 18.6447 18.1277 20.6017 15.6242 21.0291C12.2859 21.5853 9.12696 19.2605 8.70964 15.863C8.34216 12.8335 10.3693 9.972 13.3586 9.3265L13.3588 9.32646C16.6967 8.60124 19.9353 10.8164 20.5016 14.1545ZM10.379 15.1376C10.379 17.4866 12.2817 19.3892 14.6306 19.3892C16.9794 19.3892 18.8821 17.4865 18.8821 15.1376C18.8821 12.7887 16.9794 10.8861 14.6306 10.8861C12.2816 10.8861 10.379 12.7888 10.379 15.1376Z"
        fill="#525762"
        stroke="white"
        stroke-width="0.333333"
      />
      <circle cx="14.5001" cy="14.9998" r="4.33333" fill="#E1E3E6" />
      <path
        d="M50.1667 42C46.8567 42 44.1667 44.69 44.1667 48C44.1667 51.31 46.8567 54 50.1667 54C53.4768 54 56.1667 51.31 56.1667 48C56.1667 44.69 53.4868 42.01 50.1667 42ZM50.1667 52C47.9568 52 46.1667 50.2101 46.1667 48C46.1667 45.7899 47.9567 44 50.1667 44C52.3768 44 54.1668 45.7899 54.1668 48C54.1668 50.2101 52.3768 52 50.1667 52Z"
        fill="#FC4236"
      />
      <circle cx="50.1667" cy="48" r="4" fill="#FEB3AF" />
    </g>
    <defs>
      <clipPath id="clip0_1539_2309">
        <rect
          width="48"
          height="48"
          fill="white"
          transform="translate(8.5 8)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
